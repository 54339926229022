<!-- <dev-nav></dev-nav> -->
<div style="padding: 20px;max-width:750px;">
    <h1 class="m-0">{{data?.title}}</h1>
    <!-- <p class="updated-date">Last Updated: {{ data?.modifiedDate | date }}</p> -->

    <p>Angular templates are the visual parts of an Angular component that use regular HTML along with special syntax. They describe only the UI for a component, not the entire HTML document.
    </p>
    <p>Note: For security reasons, you can't use &lt;script&gt; tags in Angular templates.</p>

    <h2>Angular Template Syntax Helpers</h2>
    <p>Template aliasing</p>
    <pre>
        <!-- <code [highlight]="templateAliasCode" language="xml"></code> -->
    </pre>
    <p>ng-template, ng-container, ng-For, and ngIf</p>
    <pre>
        <!-- <code [highlight]="templateExample1" language="xml"></code> -->
    </pre>
</div>