import { Component } from '@angular/core';
import { data } from '../../../app.data';

@Component({
  template: `
  <!-- <dev-nav></dev-nav> -->

  <div style="padding: 20px;max-width:900px;">
    <h1 class="m-0">{{title}}</h1>
    <p>A component to help embed Github gists in an Angular app.</p>
    <pre>
      <!-- <code [highlight]="githubEmbedCode" language="typescript"></code> -->
    </pre>
    <p>In your app, use the component as easy as this:</p>
    <pre>
      <!-- <code [highlight]="githubEmbedTemplate" language="typescript"></code> -->
    </pre>
    <p>This is how the Github Embedded gist looks using this component.</p>
    <!-- <ngx-gist [gistId]="'abuMiguel/67857c857aacd2294209f97ad310983b'"></ngx-gist> -->
    </div>
  `,
})
export class GithubEmbedComponent {
  title = data.angGithubGistEmbedData.title;
  githubEmbedTemplate =
`<ngx-gist [gistId]="'yourGitHubName/yourGistGuidHere'"></ngx-gist>`;
  githubEmbedCode =
`import { Component, Input, ViewChild, ElementRef, AfterViewInit, 
  ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'ngx-gist',
    template: \`
    <iframe #iframe type="text/javascript" width="100%" frameborder="0" 
    style="height: 400px;margin-bottom: -10px;"></iframe>
  \`,
    encapsulation: ViewEncapsulation.None
})

export class NgxGistComponent implements AfterViewInit {
    @ViewChild('iframe') iframe: ElementRef;
    @Input() gistId: string;
    @Input() file: string;

    ngAfterViewInit(): void {
        const fileName = (this.file) ? this.file : '';
        this.iframe.nativeElement.id = 'gist-' + this.gistId;
        const doc = this.iframe.nativeElement.contentDocument || 
        this.iframe.nativeElement.contentElement.contentWindow;
        const content = \`
            <html>
            <head>
              <base target="_parent">
            </head>
            <body onload="parent.document.getElementById(
              '\${this.iframe.nativeElement.id}')">
            <script type="text/javascript" 
            src="https://gist.github.com/\${this.gistId}.js?file=\${fileName}">
            </script>
            </body>
          </html>
        \`;
        doc.open();
        doc.write(content);
        doc.close();
    }
}`;
}
