import { Component } from '@angular/core';
import { data } from '../../../app.data';

@Component({
  selector: 'phone-pipe-article',
  template: `
  <!-- <dev-nav></dev-nav> -->
  <div style="padding: 20px;max-width:900px;">
    <h1 class="m-0">{{title}}</h1>
    <p>A simple phone number pipe that adds dashes and parentheses for formatting as the user types.</p>
    <pre>
      <!-- <code [highlight]="phonePipe" language="typescript"></code> -->
    </pre>
  </div>
  `,
})
export class PhonePipeComponent {
  title = data.phonePipeData.title;
  phonePipe =
`import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "phone"
})
export class PhonePipe implements PipeTransform {
  transform(value: string | undefined) {
    if (!value)
      return "";

    const rawNum: string = value.replace(/\D/g, "") ?? "";

    if (rawNum === null || rawNum.length === 0)
      return "";

    switch (rawNum.length) {
      case 0:
        return "";
      case 1:
      case 2:
      case 3:
        return rawNum;
      case 4:
      case 5:
      case 6:
        return "(" + rawNum.slice(0,3) + ") " + rawNum.slice(3);
      case 7:
      case 8:
      case 9:
      case 10:
        return "(" + rawNum.slice(0, 3) + ") " + rawNum.slice(3,6) + "-" + 
        rawNum.slice(6);
      case 11: 
        if(rawNum[0] === 1){
          return "1 (" + rawNum.slice(1,4) + ") " + rawNum.slice(4,7) + "-" + 
          rawNum.slice(7);
        }
        else{
        return rawNum;
        }
      default: 
        return rawNum;
    }
  }
}`;
}
