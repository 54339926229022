<!-- <dev-nav></dev-nav> -->

<div style="padding: 20px; max-width: 900px">
    <h1 class="m-0">{{ data?.title }}</h1>
    <!-- <p class="updated-date">Last Updated: {{ data?.modifiedDate | date }}</p> -->

    <p>Angular components are the basic building blocks of an Angular application. They can be large or small portions or the application. I recommend keeping them as small as you can. If any collection of HTML elements is repeated in your web app, you might
        consider making it a component. Components can be nested, meaning they can have child or grandchild components.
    </p>
    <h2>Basics</h2>
    <p>Coming soon...</p>
    <h3 class="mb-0">Custom Components</h3>
    <section>
        <p class="my-0">
            <a routerLink="github-gist-embed">Embed Github Gists Component</a>
        </p>
    </section>
</div>