<!-- <dev-nav></dev-nav> -->
<div style="padding: 20px; max-width: 900px">

    <h1 class="m-0">{{ data?.title }}</h1>
    <!-- <p class="updated-date">Last Updated: {{ data?.modifiedDate | date }}</p> -->

    <p>
        Angular validators are used ensure that form input values are in the correct format. The Angular framework supplies some of the most used validation functions and provides a pattern for developers to create their own.
    </p>
    <h2>Basics</h2>
    <p>
        Example: The email form control below is being evaulated to see if the value is not empty and that it passes the custom validation rules in
        <em>customEmailValidator()</em>.
    </p>
    <pre>
    <!-- <code [highlight]="basicValidator" language="typescript"></code> -->
  </pre>
    <p>
        The validator passes an object back to the control allowing you to use the information gathered to take action, such as displaying an erorr message. It's customary to pass an object with a boolean property set to true if there is an error, or null if
        there is no error.
    </p>
    <p>
        <strong>Note:</strong> The example above shows two ways to use validators in a form. In the first example you will not have access to class members using
        <code>this</code>. In the second, the arrow function ensures that you do have access to <code>this</code>. Make sure to use the proper syntax depending on whether you need access to anything outside of the validator.
    </p>
    <p><strong>Where do I put my Angular validators in my project?</strong></p>
    <p>
        As with pipes and other reusable functions, you can put them in a shared folder or wherever you want. However, unlike pipes, they don't need to be declared in the app module, they should only be imported into components when needed. Many validators will
        be completely custom to a component. In this situation many developers will write the validator function in the component itself.
    </p>
    <p>
        For more in depth information, visit the Angular documentation on
        <a target="_blank" href="https://angular.io/guide/form-validation#validator-functions">validator functions</a
    >.
  </p>
  <h2 class="mb-0">Built-in Validators</h2>
  <section>
    <p class="my-0">
      <a target="_blank" href="https://angular.io/api/forms/Validators#min"
        >Min Validator</a
      >
    </p>
    <p class="my-0">
      <a target="_blank" href="https://angular.io/api/forms/Validators#max"
        >Max Validator</a
      >
    </p>
    <p class="my-0">
      <a target="_blank" href="https://angular.io/api/forms/Validators#required"
        >Required Validator</a
      >
    </p>
    <p class="my-0">
      <a
        target="_blank"
        href="https://angular.io/api/forms/Validators#requiredtrue"
        >Required True Validator</a
      >
    </p>
    <p class="my-0">
      <a target="_blank" href="https://angular.io/api/forms/Validators#email"
        >Email Validator</a
      >
    </p>
    <p class="my-0">
      <a
        target="_blank"
        href="https://angular.io/api/forms/Validators#minlength"
        >Min Length Validator</a
      >
    </p>
    <p class="my-0">
      <a
        target="_blank"
        href="https://angular.io/api/forms/Validators#maxlength"
        >Max Length Validator</a
      >
    </p>
    <p class="my-0">
      <a target="_blank" href="https://angular.io/api/forms/Validators#pattern"
        >Pattern Validator</a
      >
    </p>
    <p class="my-0">
      <a
        target="_blank"
        href="https://angular.io/api/forms/Validators#nullvalidator"
        >Null Validator</a
      >
    </p>
    <p class="my-0">
      <a target="_blank" href="https://angular.io/api/forms/Validators#compose"
        >Multiple Validators (compose)</a
      >
    </p>
    <p class="my-0">
      <a
        target="_blank"
        href="https://angular.io/api/forms/Validators#composeasync"
        >Multiple Validators (compose) async</a
      >
    </p>
  </section>

  <h2 class="mb-0">Custom Validators</h2>
  <section>
    <p class="my-0">
      <a routerLink="password-strength-validator">Password Strength Validator</a>
    </p>
    </section>
</div>