<!-- <dev-nav></dev-nav> -->

<main style="padding: 20px">
    <h1 class="mt-0">{{data.title}}</h1>
    <section>
        <p class="mb-0"><a routerLink="pipes">Pipes</a></p>
        <p class="my-0"><a routerLink="validators">Validators</a></p>
        <p class="my-0"><a routerLink="templates">Templates</a></p>
        <p class="my-0"><a routerLink="components">Components</a></p>
    </section>
</main>