import { Component } from '@angular/core';
import { data } from '../app.data';
import { Router } from '@angular/router';
import { AppService } from '../app.service';

@Component({
  templateUrl: './dev.component.html',
})
export class DevComponent {
  data = data.devData.data;
  constructor(public appService: AppService, public router: Router) {}

  navigateTo(route: string){
    this.router.navigate([route]);
  }
}
